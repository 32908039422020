import React, { useState, useEffect } from 'react';
import { shopVar } from './lib/shopStyles';
import { CartStep, useCart } from './providers/CartProvider';
import {Header} from './components/header/Header';
import {ProductPage} from './components/purchase/product-pages/ProductPage';
import {Checkout} from './components/purchase/checkout/checkout';
import {TotalAndCtaComponent} from './components/shared/TotalAndCtaComponent';
import { useEvent } from './providers/EventProvider';
import '@fontsource/inter/400.css';  // Regular weight
import '@fontsource/inter/700.css';  // Bold weight
import '@fontsource/inter/100.css';  // Thin weight
import '@fontsource/inter/200.css';  // Extra Light weight
import '@fontsource/inter/300.css';  // Light weight
import '@fontsource/inter/500.css';  // Medium weight
import '@fontsource/inter/600.css';  // Semi Bold weight
import { Form } from './components/purchase/details/Form';
import { FastlaneLogo } from './components/shared/FastlaneLogo';

export const TicketShop: React.FC = () => {
    const { cartStep} = useCart();
    const { event } = useEvent();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const productPage = event.productPages[0];

    function renderContent(): React.ReactNode | Iterable<React.ReactNode> {
        switch (cartStep) {
            case CartStep.Tickets:
                if (!productPage) {
                    return <div>No product page found</div>;
                }
                return <ProductPage productPage={productPage}/>;
            case CartStep.Order:
                return <Form/>;
            case CartStep.Checkout:
                return <Checkout/>;
        }
    }

    return (
        <>
        <div className='min-w-[252px] md:min-w-[552px] font-sans' style={{
            color: shopVar('--shop-textColor'),
            backgroundColor: isMobile ? shopVar('--shop-panelBackgroundColor') : undefined
        }}>
            <Header/>
            
            {renderContent()}

            {(cartStep === CartStep.Order || cartStep === CartStep.Tickets) && (<TotalAndCtaComponent/>)}

            {isMobile && (
                <div className='flex justify-center pb-4'>
                    <FastlaneLogo />
                </div>
            )}
        </div>        
        {!isMobile && (<FastlaneLogo />)}
        </>
    );
};