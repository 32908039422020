import React from 'react';
import * as Select from '@radix-ui/react-select';
import { RiArrowDownSLine } from '@remixicon/react';
import { shopVar } from '@/lib/shopStyles';
import { useLanguage } from '@/providers/LanguageProvider';

const languages = [
  { value: 'en', label: '🇬🇧 English' },
  { value: 'nl', label: '🇳🇱 Nederlands' },
];

export const LanguageSelect: React.FC = () => {
  const { currentLanguage, changeLanguage } = useLanguage();

  return (
    <div className="relative z-50">
      <Select.Root 
        value={currentLanguage}
        onValueChange={changeLanguage}
      >
        <Select.Trigger
          className="opacity-80 inline-flex items-center justify-center focus:shadow-[0_0_0_1px] outline-none"
          aria-label="Language"
          style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}
        >
          <Select.Value placeholder="Select a language.." />
          <Select.Icon className="">
            <RiArrowDownSLine />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content 
          className="z-50" 
          position="popper" 
          style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}
        >
          <Select.Viewport className='p-1' style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
            {languages.map((language) => (
              <Select.Item 
                key={language.value} 
                value={language.value} 
                className='p-2 cursor-pointer'
              >
                <Select.ItemText>{language.label}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Root>
    </div>
  );
};