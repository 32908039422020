import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { CartItem, CartState, Marketplace, MarketplaceApi } from '@/lib/MarketplaceApi';
import { ulid } from 'ulidx';
import { Money } from '@/lib/money';
import { calculateFee } from '@/lib/calculateFee';
const CART_ID_KEY = 'marketplace_cart_id';

interface CheckoutResponse {
  type: "redirect" | "error";
  url?: string;
  error?: string;
}

interface MarketplaceCartContextType {
  cart: CartState;
  cartTotal: Money;
  marketplace: Marketplace;
  getCartItemTotal: (item: CartItem) => Money;
  updateQuantity: (listingId: string, quantity: number) => void;
  startCheckout: (paymentMethodId: string) => Promise<CheckoutResponse>;
  isLoading: Record<string, boolean>;
  cartId: string;
  formState: object;
  setFormState: (formState: object) => void;
  formValid: boolean;
  setFormValid: (formValid: boolean) => void;
  checkoutLoading: boolean;
  refreshCart: () => void;
  setCartIdFromRouteSlug: (cartId: string) => void;
}

const MarketplaceCartContext = createContext<MarketplaceCartContextType | null>(null);

function getOrCreateCartId(): string {
  const existingCartId = localStorage.getItem(CART_ID_KEY);
  if (existingCartId) {
    return existingCartId;
  }
  
  const newCartId = ulid();
  localStorage.setItem(CART_ID_KEY, newCartId);
  return newCartId;
}

export function MarketplaceCartProvider({ children, slug }: { children: React.ReactNode, slug: string }) {
  const [cart, setCart] = useState<CartState>({reserved: [], cartId: '', listings: [], answers: {}, pendingPayment: false, orderId: null});
  const [marketplace, setMarketplace] = useState<Marketplace|null>(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const [cartId, setCartId] = useState(() => getOrCreateCartId());
  const marketplaceApi = new MarketplaceApi(import.meta.env.VITE_API_URL);
  const [formState, setFormState] = useState<object>({});
  const [formValid, setFormValid] = useState(false);

  const loadCart = async () => {
    try {
      const cartState = await marketplaceApi.getCart(slug, cartId);
      setCart(cartState);
      setFormState(cartState.answers);
    } catch (error) {
      console.error('Failed to load cart:', error);
    }
  };

  const loadMarketplace = async (slug: string) => {
    const marketplace = await marketplaceApi.getMarketplace(slug);
    setMarketplace(marketplace);
    mergeMarketplaceListings();
  }

  const setCartIdFromRouteSlug = async (newCartId: string) => {
    if(newCartId === cartId) {
      return;
    }
    setCartId(newCartId);
    localStorage.setItem(CART_ID_KEY, newCartId);
    const cartState = await marketplaceApi.getCart(slug, newCartId);
    setCart(cartState);
  }

  const refreshCart = async () => {
    // create new cart id
    const newCartId = ulid();
    setCartId(newCartId);
    localStorage.setItem(CART_ID_KEY, newCartId);
    const cartState = await marketplaceApi.getCart(slug, newCartId);
    setCart(cartState);
  }

  const mergeMarketplaceListings = () => {
    if(marketplace === null) {
      return;
    }

    const uniqueListings = new Map();
    
    // Add marketplace listings first
    marketplace.listings.forEach(listing => {
      uniqueListings.set(listing.id, listing);
    });
    
    // Cart listings will override marketplace listings if they exist
    cart.listings?.forEach(listing => {
      uniqueListings.set(listing.id, listing);
    });

    setMarketplace({
      ...marketplace,
      listings: Array.from(uniqueListings.values())
    });
  }

  // Load initial cart state
  useEffect(() => {
    loadCart();
  }, [cartId]);

  useEffect(() => {
    if(cart.pendingPayment) {
      // load cart again every second
      const interval = setInterval(() => {
        loadCart();
      }, 1000);
      return () => clearInterval(interval);
    }

    mergeMarketplaceListings();
  }, [cart]);

  useEffect(() => {
    loadMarketplace(slug);
  }, [slug]);

  const updateQuantity = useCallback(async (listingId: string, quantity: number) => {
    setIsLoading(prev => ({ ...prev, [listingId]: true }));
    const cartState = await marketplaceApi.reserveListing(slug, cartId, listingId, quantity);
    setCart(cartState);
    setIsLoading(prev => ({ ...prev, [listingId]: false }));
  }, []);

  const getCartItemTotal = (item: CartItem): Money => {
    const totalForListing = Money.fromInteger(item.price.amount * item.quantity, item.price.currency);
    return totalForListing.add(calculateFee(item.fee, totalForListing, item.quantity));
  }

  const cartTotal = useMemo(() => {
    let total = Money.fromInteger(0, cart.reserved[0]?.price?.currency ?? 'EUR');
    cart.reserved.forEach(item => {
        total = total.add(getCartItemTotal(item));
    });
    return total;
  }, [cart]);

  const startCheckout = async (paymentMethodId: string): Promise<CheckoutResponse> => {
    setCheckoutLoading(true);
    try {
      const result = await marketplaceApi.checkout(slug, cartId, formState, paymentMethodId);
      if(result.type === "redirect") {
        window.location.href = result.url;
      }
      return result;
    } catch (error) {
      console.error('Failed to start checkout:', error);
      return { type: "error", error: "Failed to start checkout" };
    } finally {
      setCheckoutLoading(false);
    }
  }

  if(!marketplace) return null;

  return (
    <MarketplaceCartContext.Provider value={{ checkoutLoading, cart, updateQuantity, isLoading, cartId, cartTotal, getCartItemTotal, startCheckout, marketplace, formState, setFormState, formValid, setFormValid, refreshCart, setCartIdFromRouteSlug}}>
      {children}
    </MarketplaceCartContext.Provider>
  );
}

export function useMarketplaceCart() {
  const context = useContext(MarketplaceCartContext);
  if (!context) {
    throw new Error('useMarketplaceCart must be used within a MarketplaceCartProvider');
  }
  return context;
}
